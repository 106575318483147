import { Button } from '@admin/components/Button';
import { SidebarNavLink } from '@admin/components/MainLayout/SidebarNavLink';
import { utils } from '@admin/utils';
import { routeName } from '@admin/utils/routeName';
import { css } from '@emotion/react';

import { StatisticsFilterForTestAccounts } from './StatisticsFilterForTestAccounts';
import { UsersPerPlan } from './UsersPerPlan';

const menus = [
  // 통계, 로그
  [
    {
      to: `/${routeName.statistics}`,
      title: '통계',
    },
    {
      to: `/${routeName.activeUserStatistics}`,
      title: '일별/월별 사용자 통계',
    },
    {
      to: `/${routeName.gpuStatistics}`,
      title: 'GPU 가동률',
    },
    {
      to: `/${routeName.monthlyStatistics}`,
      title: '월별 보이스 통계',
    },
    {
      to: `/${routeName.myAiVoiceStatistics}`,
      title: '마이 AI 보이스 현황',
    },
  ],
  // 정산
  [
    {
      to: `/${routeName.humeloStatistics}`,
      title: '휴멜로 정산',
    },
    {
      to: `/${routeName.specialVoiceSettlement}`,
      title: '특별 보이스 통계/정산',
    },
  ],
  // 운영
  [
    {
      to: `/${routeName.inquiry}`,
      title: '고객 문의',
    },
    {
      to: `/${routeName.email}`,
      title: '메일',
    },
    {
      to: `/${routeName.voucher}`,
      title: '이벤트 쿠폰',
    },
  ],
  // 유저 관리
  [
    {
      to: `/${routeName.user}`,
      title: '유저',
    },
    {
      to: `/${routeName.plan}`,
      title: '요금제',
    },
    {
      to: `/${routeName.serviceCode}`,
      title: '서비스 코드',
    },
  ],
  // 보이스 관리
  [
    {
      to: `/${routeName.voice}`,
      title: '보이스',
    },
    {
      to: `/${routeName.tag}`,
      title: '보이스 태그',
    },
    {
      to: `/${routeName.voiceContract}`,
      title: '보이스 계약',
    },
    {
      to: `/${routeName.voiceMapping}`,
      title: '보이스 맵핑',
    },
    {
      to: `/${routeName.pronunciationMaps}`,
      title: '발음 교정',
    },
    {
      to: `/${routeName.script}`,
      title: '녹음 스크립트',
    },
  ],
  // 홈페이지 관리
  [
    {
      to: `/${routeName.notice}`,
      title: '공지사항',
    },
    {
      to: `/${routeName.faq}`,
      title: 'FAQ',
    },
    {
      to: `/${routeName.terms}`,
      title: '약관 및 정책',
    },
    {
      to: `/${routeName.mailTemplate}`,
      title: '메일 템플릿',
    },
    {
      to: `/${routeName.eventPage}`,
      title: '이벤트 페이지 관리',
    },
  ],
] as const;

export const Sidebar = (props: { width: number }) => {
  return (
    <aside
      css={css`
        position: absolute;
        top: 0;
        left: 0;

        width: ${props.width}px;
        height: 100vh;

        background: ${utils.style.color.white};
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

        display: flex;
        flex-direction: column;
        padding: 12px;
        overflow-y: auto;
        gap: 1rem;
      `}
    >
      <h2
        css={css`
          margin: 0;
          text-align: center;
          color: ${utils.style.color.black};
        `}
      >
        보이스 스튜디오
        <br />
        관리자 페이지
      </h2>

      {/* 요금제 별 사용자 수 */}
      <UsersPerPlan />

      <StatisticsFilterForTestAccounts />

      {/* 메뉴 */}
      <nav
        css={css`
          height: 100%;
          overflow-x: hidden;
          overflow-y: auto;
        `}
      >
        <ul
          css={css`
            display: flex;
            flex-direction: column;
            gap: 24px;
          `}
        >
          {menus.map((menuGroup) => (
            <div key={menuGroup[0].title}>
              {menuGroup.map((menu) => (
                <li key={menu.to}>
                  <SidebarNavLink to={menu.to} title={menu.title} />
                </li>
              ))}
            </div>
          ))}
        </ul>
      </nav>

      <Button onClick={() => utils.token.clear()}>로그아웃</Button>
    </aside>
  );
};
