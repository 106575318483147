/* eslint-disable import/no-duplicates */
import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { DatePickerButton } from '@admin/components/DatePicker/DatePickerButton';
import { StyledH1 } from '@admin/components/Styled/H1';
import { css } from '@emotion/react';
import { isBefore } from 'date-fns';
import ko from 'date-fns/esm/locale/ko';
import dayjs from 'dayjs';
import { createElement, useState } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import {
  bundleDownloadExcel,
  bundleRefundDownloadExcel,
  createDownloadExcel,
  singleDownloadExcel,
  singleRefundDownloadExcel,
} from './downloadStat';

registerLocale('ko-KR', ko);

export const AiVoiceStatisticsPage = () => {
  const [date, setDate] = useState({
    signup: new Date('2024-01-12'),
    myaivoice: new Date('2024-02-01'),
  });

  return (
    <ContentBox
      css={css`
        height: 100%;
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 32px;
          flex-direction: column;
          justify-content: space-between;
        `}
      >
        <StyledH1>마이 AI 보이스 현황</StyledH1>

        <div
          css={css`
            display: flex;
            gap: 16px;
            flex-direction: column;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 32px;
            `}
          >
            <div>
              <StyledH1>기준 회원 가입일</StyledH1>

              <div>
                <ReactDatePicker
                  required
                  dateFormat="yyyy년 M월 d일 이후"
                  selected={date.signup}
                  onChange={(date) => {
                    if (date) setDate((prev) => ({ ...prev, signup: date }));
                  }}
                  locale="ko-KR"
                  customInput={createElement(DatePickerButton)}
                  filterDate={(date) => isBefore(date, new Date())}
                />
              </div>
            </div>

            <div>
              <StyledH1>마이 AI 보이스 생성 기준일</StyledH1>

              <div>
                <ReactDatePicker
                  required
                  dateFormat="yyyy년 M월 d일 이후"
                  selected={date.myaivoice}
                  onChange={(date) => {
                    if (date) setDate((prev) => ({ ...prev, myaivoice: date }));
                  }}
                  locale="ko-KR"
                  customInput={createElement(DatePickerButton)}
                  filterDate={(date) => isBefore(date, new Date())}
                />
              </div>
            </div>
          </div>

          <StyledH1>통계 출력</StyledH1>

          <div
            css={css`
              display: flex;
              gap: 10px;
              flex-direction: column;
            `}
          >
            <p>잔여 이용권 및 마이 AI 보이스 생성 현황</p>
            <Button
              onClick={() =>
                createDownloadExcel(
                  dayjs(date.signup).format('YYYY-MM-DD'),
                  dayjs(date.myaivoice).format('YYYY-MM-DD'),
                )
              }
            >
              Excel로 내보내기
            </Button>
            <p>단건 결제 현황</p>
            <Button
              onClick={() =>
                singleDownloadExcel(
                  dayjs(date.signup).format('YYYY-MM-DD'),
                  dayjs(date.myaivoice).format('YYYY-MM-DD'),
                )
              }
            >
              Excel로 내보내기
            </Button>
            <p>번들 요금제로 취득한 마이 AI 보이스 결제 이력</p>
            <Button onClick={bundleDownloadExcel}>Excel로 내보내기</Button>
            <p>단건 환불 내역</p>
            <Button
              onClick={() =>
                singleRefundDownloadExcel(
                  dayjs(date.signup).format('YYYY-MM-DD'),
                  dayjs(date.myaivoice).format('YYYY-MM-DD'),
                )
              }
            >
              Excel로 내보내기
            </Button>
            <p>번들 환불 내역</p>
            <Button
              onClick={() =>
                bundleRefundDownloadExcel(
                  dayjs(date.signup).format('YYYY-MM-DD'),
                  dayjs(date.myaivoice).format('YYYY-MM-DD'),
                )
              }
            >
              Excel로 내보내기
            </Button>
          </div>
        </div>
      </div>
    </ContentBox>
  );
};
