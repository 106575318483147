import { Button } from '@admin/components/Button';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { repositoryClient } from '@admin/repository';
import {
  DISPLAY_NAMES_MEMBER,
  DISPLAY_NAMES_SUBSCRIPTION,
} from '@admin/repository/constant';
import type { Member, Pageable } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { useMutation } from '@tanstack/react-query';
import _ from 'lodash-es';
import { utils as XLSXUtils, writeFileXLSX } from 'xlsx';

export const ExportButton = () => {
  const { openModal } = useModal();

  const { mutate: exportMemberData, isLoading: isExporting } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    mutationFn: async () => {
      const {
        data: {
          data: { totalElements: numMembers },
        },
      } = await repositoryClient.get<{ data: Pageable<Member[]> }>(
        'member/all',
        { params: { size: 1 } },
      );

      const columns = {
        role: DISPLAY_NAMES_MEMBER.role,
        serviceCode: DISPLAY_NAMES_MEMBER.serviceCode,
        email: DISPLAY_NAMES_MEMBER.email,
        provider: DISPLAY_NAMES_MEMBER.provider,
        registerDate: DISPLAY_NAMES_MEMBER.registerDate,
        isDeleted: DISPLAY_NAMES_MEMBER.isDeleted,
        isEnabled: DISPLAY_NAMES_MEMBER.isEnabled,
        isBlocked: DISPLAY_NAMES_MEMBER.isBlocked,
        isDormant: DISPLAY_NAMES_MEMBER.isDormant,
        loginFailCount: DISPLAY_NAMES_MEMBER.loginFailCount,
        name: DISPLAY_NAMES_MEMBER.name,
        phone: DISPLAY_NAMES_MEMBER.phone,
        birth: DISPLAY_NAMES_MEMBER.birth,
        currentPlanName: DISPLAY_NAMES_MEMBER.currentSubscription,
        since: DISPLAY_NAMES_SUBSCRIPTION.since,
        till: DISPLAY_NAMES_SUBSCRIPTION.till,
        enableCharactersCount: DISPLAY_NAMES_SUBSCRIPTION.enableCharactersCount,
        enableCustomVoiceCount:
          DISPLAY_NAMES_SUBSCRIPTION.enableCustomVoiceCount,
        region: DISPLAY_NAMES_MEMBER.region,
        language: DISPLAY_NAMES_MEMBER.language,
        isCheckUnifiedRegistration:
          DISPLAY_NAMES_MEMBER.isCheckUnifiedRegistration,
        isCheckMarketingTerm: DISPLAY_NAMES_MEMBER.isCheckMarketingTerm,
        passwordExpiredAt: DISPLAY_NAMES_MEMBER.passwordExpiredAt,
        creatorEmail: DISPLAY_NAMES_MEMBER.creatorEmail,
        updaterEmail: DISPLAY_NAMES_MEMBER.updaterEmail,
      } as const;

      const iter = Math.floor(numMembers / 40000);
      const iterCount = new Array(iter).fill(0).map((_, index) => index);

      const memberDivide: Member[] = [];

      for await (const iter of iterCount) {
        const memberList = await repositoryClient
          .get<{ data: Pageable<Member[]> }>('/member/all?isDownload=true', {
            params: { page: iter, size: 40000 },
            timeout: 60000000,
          })
          .then(
            ({
              data: {
                data: { data },
              },
            }) => data,
          );

        memberDivide.push(...memberList);
      }
      if (numMembers % 40000 > 0) {
        const memberList = await repositoryClient
          .get<{ data: Pageable<Member[]> }>('/member/all?isDownload=true', {
            params: { page: iter, size: numMembers - iter * 40000 },
            timeout: 60000000,
          })
          .then(
            ({
              data: {
                data: { data },
              },
            }) => data,
          );

        memberDivide.push(...memberList);
      }

      type ColumnKey = keyof typeof columns;

      const exportedData = _.chain(memberDivide)

        // column 데이터 추출
        .map(
          (row) =>
            ({
              birth: row.birth,
              phone: row.phone,
              creatorEmail: row.creatorEmail,
              currentPlanName: row.currentSubscription.plan.name,
              email: row.email,
              enableCharactersCount:
                row.currentSubscription.enableCharactersCount,
              enableCustomVoiceCount:
                row.currentSubscription.enableCustomVoiceCount,
              isBlocked: row.isBlocked,
              isCheckUnifiedRegistration: row.isCheckUnifiedRegistration,
              isCheckMarketingTerm: row.isCheckMarketingTerm,
              isDeleted: row.isDeleted,
              isEnabled: row.isEnabled,
              isDormant: row.isDormant,
              language: row.language,
              loginFailCount: row.loginFailCount,
              name: row.name,
              passwordExpiredAt: row.passwordExpiredAt,
              provider: row.provider,
              region: row.region,
              registerDate: row.registerDate,
              role: row.role,
              serviceCode: row.serviceCode,
              since: row.currentSubscription.since,
              till: row.currentSubscription.till,
              updaterEmail: row.updaterEmail,
            } satisfies Record<ColumnKey, unknown>),
        )

        // column 이름 한글화
        .map((row) => {
          type RowValues = (typeof row)[keyof typeof row];
          return _.chain(_.entries(columns) as [ColumnKey, string][])
            .map<[string, RowValues]>(([key, translated]) => [
              translated,
              row[key],
            ])
            .reduce(
              (o, [k, v]) => ({ ...o, [k]: v }),
              {} as Record<string, RowValues>,
            )
            .value();
        })
        .value();

      const dateString = new Date().toLocaleDateString('ko-KR', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      });
      const wb = XLSXUtils.book_new();
      const ws = XLSXUtils.json_to_sheet(exportedData);
      XLSXUtils.book_append_sheet(wb, ws, dateString);
      writeFileXLSX(wb, `KT AI Voice 전체 서비스 이용자 ${dateString}.xlsx`);
    },
  });

  return (
    <Button disabled={isExporting} onClick={exportMemberData}>
      내보내기
    </Button>
  );
};
