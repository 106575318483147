export const routeName = {
  login: 'login',
  passwordExpired: 'passwordexpired',
  statistics: 'statistics',
  activeUserStatistics: 'activeuserstatistics',
  gpuStatistics: 'gpustatistics',
  humeloStatistics: 'humelostatistics',
  user: 'user',
  voice: 'voice',
  specialVoiceSettlement: 'special-voice-settlement',
  voiceMapping: 'voicemapping',
  voiceContract: 'voicecontract',
  tag: 'tag',
  script: 'script',
  notice: 'notice',
  plan: 'plan',
  terms: 'terms',
  mailTemplate: 'mailtemplate',
  serviceCode: 'service-code',
  voucher: 'voucher',
  inquiry: 'inquiry',
  faq: 'faq',
  email: 'email',
  eventPage: 'eventPage',
  pronunciationMaps: 'pronunciation-maps',
  monthlyStatistics: 'monthlystatistics',
  myAiVoiceStatistics: 'aivoicestatistics',
} as const;
