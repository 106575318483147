import { EventManagingEditPage } from '@admin/routes/eventPage/edit';
import { EventPageList } from '@admin/routes/eventPage/list';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuthorizationContext } from './components/AuthorizationContextProvider';
import { MainLayout } from './components/MainLayout';
import { ProtectedLayout } from './components/ProtectedLayout';
import { ActiveUserStatisticsPage } from './routes/active_user_statistics/ActiveUserStatisticsPage';
import { AiVoiceStatisticsPage } from './routes/aivoicestatistics';
import { EmailCreatePage } from './routes/email/create';
import { EmailListPage } from './routes/email/list';
import { EmailViewPage } from './routes/email/view';
import { FAQCreatePage } from './routes/faq/create';
import { FAQEditPage } from './routes/faq/edit';
import { FAQListPage } from './routes/faq/list';
import { GPUStatisticsPage } from './routes/gpu_statistics/GPUStatisticsPage';
import { HumeloStatisticsPage } from './routes/humelo_statistics';
import { InquiryEditPage } from './routes/inquiry/edit';
import { InquiryListPage } from './routes/inquiry/list';
import { InquiryStatisticsPage } from './routes/inquiry/statistics';
import { LoginPage } from './routes/login';
import { LogoPreviewPage } from './routes/mail_template/LogoPreviewPage';
import { MailTemplatePage } from './routes/mail_template/MailTemplatePage';
import { MailTemplatePreviewPage } from './routes/mail_template/MailTemplatePreviewPage';
import { MailTitlePreviewPage } from './routes/mail_template/MailTitlePreviewPage';
import { SignUpPreviewPage } from './routes/mail_template/SignUpPreviewPage';
import { MonthlyStatisticsPage } from './routes/monthlystatistics';
import { NoticeCreatePage } from './routes/notice/NoticeCreatePage';
import { NoticeEditorPage } from './routes/notice/NoticeEditorPage';
import { NoticeListPage } from './routes/notice/NoticeListPage';
import { PasswordExpiredPage } from './routes/password_expired';
import { PlanEditorPage } from './routes/plan/edit';
import { PlanListPage } from './routes/plan/list';
import { PlanStatisticsPage } from './routes/plan/statistics';
import { PronunciationMapsPage } from './routes/pronunciation_maps';
import { ScriptEditorPage } from './routes/script/ScriptEditorPage';
import { ScriptListPage } from './routes/script/ScriptListPage';
import { ServiceCodeEditingPage } from './routes/service-code/edit';
import { ServiceCodeListPage } from './routes/service-code/list';
import { ServiceCodeStatisticsPage } from './routes/service-code/statistics';
import { SpecialVoiceSettlementPage } from './routes/special-voice-settlement';
import { SpecialVoiceSettlementListPage } from './routes/special-voice-settlement/list';
import { StatisticsPage } from './routes/statistics/StatisticsPage';
import { TagEditorPage } from './routes/tag/TagEditorPage';
import { TagListPage } from './routes/tag/TagListPage';
import { TermsEditPage } from './routes/terms/edit';
import { TermsHistoryPage } from './routes/terms/history';
import { TermsListPage } from './routes/terms/list';
import { TermsUploadPage } from './routes/terms/upload';
import { UserCreatePage } from './routes/user/create';
import { UserEditorPage } from './routes/user/edit';
import { UserListPage } from './routes/user/list';
import { VoiceContractPage } from './routes/voice/contract';
import { CreateVoicePage } from './routes/voice/create';
import { VoiceEditPage } from './routes/voice/edit';
import { VoiceListPage } from './routes/voice/list';
import { VoiceMappingEditorPage } from './routes/voiceMapping/edit';
import { VoiceMappingListPage } from './routes/voiceMapping/list';
import { VoucherEditPage } from './routes/voucher/edit';
import { VoucherListPage } from './routes/voucher/list';
import { routeName } from './utils/routeName';

export const App = () => {
  const auth = useAuthorizationContext();

  const entryRoute =
    auth.status === 'authorized' ? routeName.statistics : routeName.login;

  return (
    <Routes>
      <Route path="*" element={<Navigate to=".." replace relative="path" />} />

      <Route index element={<Navigate to={`/${entryRoute}`} replace />} />

      <Route
        element={
          <ProtectedLayout
            redirectPath={`/${entryRoute}`}
            isAllowed={auth.status === 'unauthorized'}
          />
        }
      >
        {/* 로그인 */}
        <Route path={`/${routeName.login}`}>
          <Route index element={<LoginPage />} />
        </Route>
      </Route>

      <Route
        element={
          <ProtectedLayout
            redirectPath={`/${entryRoute}`}
            isAllowed={auth.status === 'authorized'}
          />
        }
      >
        {/* 패스워드 만료 */}
        <Route path={`/${routeName.passwordExpired}`}>
          <Route index element={<PasswordExpiredPage />} />
        </Route>

        <Route element={<MainLayout />}>
          {/* 통계 */}
          <Route path={`/${routeName.statistics}`}>
            <Route index element={<StatisticsPage />} />
          </Route>
          {/* 일/월별 사용자 통계 */}
          <Route path={`/${routeName.activeUserStatistics}`}>
            <Route index element={<ActiveUserStatisticsPage />} />
          </Route>
          {/* GPU 가동률 */}
          <Route path={`/${routeName.gpuStatistics}`}>
            <Route index element={<GPUStatisticsPage />} />
          </Route>
          {/* 월별 보이스 통계 */}
          <Route path={`/${routeName.monthlyStatistics}`}>
            <Route index element={<MonthlyStatisticsPage />} />
          </Route>
          {/* 마이 AI 보이스 현황 */}
          <Route path={`/${routeName.myAiVoiceStatistics}`}>
            <Route index element={<AiVoiceStatisticsPage />} />
          </Route>
          {/* 휴멜로 정산 */}
          <Route path={`/${routeName.humeloStatistics}`}>
            <Route index element={<HumeloStatisticsPage />} />
          </Route>
          {/* 유저 관리 */}
          <Route path={`/${routeName.user}`}>
            <Route index element={<UserListPage />} />
            <Route path="edit/:email" element={<UserEditorPage />} />
            <Route path="create" element={<UserCreatePage />} />
          </Route>
          {/* 보이스 관리 */}
          <Route path={`/${routeName.voice}`}>
            <Route index element={<VoiceListPage />} />
            <Route path="edit/:name" element={<VoiceEditPage />} />
            <Route path="create" element={<CreateVoicePage />} />
          </Route>
          {/* 보이스 ID 매핑 */}
          <Route path={`/${routeName.voiceMapping}`}>
            <Route index element={<VoiceMappingListPage />} />
            <Route
              path="edit/:speaker_id"
              element={<VoiceMappingEditorPage />}
            />
          </Route>
          {/* 발음 교정 */}
          <Route path={`/${routeName.pronunciationMaps}`}>
            <Route index element={<PronunciationMapsPage />} />
          </Route>
          {/* 보이스 계약 */}
          <Route path={`/${routeName.voiceContract}`}>
            <Route index element={<VoiceContractPage />} />
          </Route>
          {/* 특별 보이스 통계/정산 */}
          <Route path={`/${routeName.specialVoiceSettlement}`}>
            <Route index element={<SpecialVoiceSettlementListPage />} />
            <Route path=":name" element={<SpecialVoiceSettlementPage />} />
          </Route>
          {/* 요금제 관리 */}
          <Route path={`/${routeName.plan}`}>
            <Route index element={<PlanListPage />} />
            <Route path="edit/:uid" element={<PlanEditorPage />} />
            <Route path="statistics" element={<PlanStatisticsPage />} />
          </Route>
          {/* 약관 */}
          <Route path={`/${routeName.terms}`}>
            <Route index element={<TermsListPage />} />
            <Route path="upload/:termsId" element={<TermsUploadPage />} />
            <Route path="history/:termsId" element={<TermsHistoryPage />} />
            <Route
              path="history/:termsId/preview/:termsHistoryId"
              element={<TermsEditPage />}
            />
          </Route>
          {/* 공지사항 */}
          <Route path={`/${routeName.notice}`}>
            <Route index element={<NoticeListPage />} />
            <Route path="create" element={<NoticeCreatePage />} />
            <Route path="edit/:id" element={<NoticeEditorPage />} />
          </Route>
          {/* 고객 문의 */}
          <Route path={`/${routeName.inquiry}`}>
            <Route index element={<InquiryListPage />} />
            <Route path="edit/:inquiryId" element={<InquiryEditPage />} />
            <Route path="statistics" element={<InquiryStatisticsPage />} />
          </Route>
          {/* 태그 관리 */}
          <Route path={`/${routeName.tag}`}>
            <Route index element={<TagListPage />} />
            <Route path="edit/:id" element={<TagEditorPage />} />
          </Route>
          {/* 녹음 스크립트 관리 */}
          <Route path={`/${routeName.script}`}>
            <Route index element={<ScriptListPage />} />
            <Route path="edit/:uuid" element={<ScriptEditorPage />} />
          </Route>
          {/* 메일 템플릿 */}
          <Route path={`/${routeName.mailTemplate}`}>
            <Route index element={<MailTemplatePage />} />
            <Route path="LOGO" element={<LogoPreviewPage />} />
            <Route path="SIGN_UP_LOGO" element={<SignUpPreviewPage />} />
            <Route path="MAIL_TITLE" element={<MailTitlePreviewPage />} />
            <Route
              path="preview/:mailTemplateName"
              element={<MailTemplatePreviewPage />}
            />
          </Route>
          {/* 서비스 코드 관리 */}
          <Route path={`/${routeName.serviceCode}`}>
            <Route index element={<ServiceCodeListPage />} />
            <Route
              path="edit/:serviceCode"
              element={<ServiceCodeEditingPage />}
            />
            <Route
              path="statistics/:serviceCode"
              element={<ServiceCodeStatisticsPage />}
            />
            <Route
              path="*"
              element={<Navigate to={`/${routeName.serviceCode}`} />}
            />
          </Route>
          {/* 이벤트 쿠폰 */}
          <Route path={`/${routeName.voucher}`}>
            <Route index element={<VoucherListPage />} />
            <Route path="edit/:code" element={<VoucherEditPage />} />
          </Route>
          {/* FAQ */}
          <Route path={`/${routeName.faq}`}>
            <Route index element={<FAQListPage />} />
            <Route path="create" element={<FAQCreatePage />} />
            <Route path="edit/:faqId" element={<FAQEditPage />} />
          </Route>
          {/* 메일 */}
          <Route path={`/${routeName.email}`}>
            <Route index element={<EmailListPage />} />
            <Route path="create" element={<EmailCreatePage />} />
            <Route path="view/:reservationMailId" element={<EmailViewPage />} />
          </Route>
          {/* 이벤트 페이지 관리 */}
          <Route path={`/${routeName.eventPage}`}>
            <Route index element={<EventPageList />} />
            <Route
              path="edit/:eventPageId"
              element={<EventManagingEditPage />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
