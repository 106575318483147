import { Button } from '@admin/components/Button';
import { StyledTable } from '@admin/components/Styled/Table';
import { repositoryClient } from '@admin/repository';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { faqCategories } from '../../constant';

interface FAQContents {
  id: number;
  category: 'SERVICE' | 'ACCOUNT' | 'PAYMENT' | 'OPERATING_SYSTEM';
  title: string;
  isEnabled: boolean;
  contents: string;
  creatorEmail: string;
  modifierEmail: string;
  createdAt: string;
  modifiedAt: string;
}

export const FAQListTable = ({
  searchValue,
  categoryValue,
  searchTarget,
}: {
  searchValue: string;
  categoryValue: string;
  searchTarget: string;
}) => {
  const navigate = useNavigate();

  const { data: faqListData } = useQuery({
    queryKey: ['/admin-faq'] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: FAQContents[] }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(faqListData, 'Invalid faqListData');

  return (
    <div
      css={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <StyledTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>카테고리</th>
            <th>제목</th>
            <th>작성자</th>
            <th>수정자</th>
            <th>작성일자</th>
            <th>수정일자</th>
            <th>활성화 여부</th>
            <th>관리</th>
          </tr>
        </thead>
        <tbody>
          {faqListData
            .filter((item: FAQContents) =>
              categoryValue === 'ALL' ? true : item.category === categoryValue,
            )
            .filter((item: FAQContents) =>
              searchTarget === 'title'
                ? item.title.includes(searchValue)
                : item.contents.includes(searchValue),
            )
            .map((item: FAQContents) => (
              <tr
                key={item.id}
                style={{
                  background: item.isEnabled ? undefined : 'rgba(0, 0, 0, 0.2)',
                }}
              >
                <td>{item.id}</td>
                <td>{faqCategories[item.category]}</td>
                <td>{item.title}</td>
                <td>{item.creatorEmail}</td>
                <td>{item.modifierEmail}</td>
                <td>{utilTime.format(Date.parse(item.createdAt), true)}</td>
                <td>{utilTime.format(Date.parse(item.modifiedAt), true)}</td>
                <td>{item.isEnabled ? 'O' : 'X'}</td>
                <td>
                  <div
                    css={css`
                      display: flex;
                      justify-content: center;
                    `}
                  >
                    <Button
                      baseColor={utils.style.color.subBlack}
                      onClick={() => navigate(`./edit/${item.id}`)}
                    >
                      관리
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </StyledTable>
    </div>
  );
};
