import { Button } from '@admin/components/Button';
import { ContentBox } from '@admin/components/ContentBox';
import { AlertDialog } from '@admin/components/Dialog/AlertDialog';
import { DeleteDialog } from '@admin/components/Dialog/DeleteDialog';
import { PromptDialog } from '@admin/components/Dialog/PromptDialog';
import { useModal } from '@admin/components/Modal/hooks';
import { StyledForm } from '@admin/components/Styled/Form';
import { StyledH1 } from '@admin/components/Styled/H1';
import { TextEditor } from '@admin/components/textEditor/TextEditor';
import { repositoryClient } from '@admin/repository';
import type { Notice } from '@admin/repository/types';
import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import { invariant } from '@admin/utils/invariant';
import utilTime from '@admin/utils/utilTime';
import { css } from '@emotion/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { faqCategories } from '../constant';

export const FAQEditPage = () => {
  const queryClient = useQueryClient();
  const { openModal } = useModal();

  const formRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();

  const { faqId } = useParams();

  const { data: faqData } = useQuery({
    queryKey: [`/admin-faq/${faqId}`, { faqId }] as const,
    queryFn: ({ queryKey: [path], signal }) =>
      repositoryClient.get<{ data: Notice }>(path, { signal }),
    select: ({ data: { data } }) => data,
  });

  invariant(faqData, 'Invalid faqData');

  const [formValue, setFormValue] = useState<
    Pick<Notice, 'category' | 'contents' | 'title' | 'nextFaqId' | 'isEnabled'>
  >({
    category: faqData.category,
    title: faqData.title,
    contents: faqData.contents,
    nextFaqId: faqData.nextFaqId,
    isEnabled: faqData.isEnabled,
  });

  const { mutate: updateFAQ } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin-faq/${faqId}`, { faqId }],
      });
      navigate('..');
    },
    mutationFn: async () => {
      // 변경된 값만 전송
      const data = Object.fromEntries(
        Object.entries({ ...formValue }).filter(
          ([key, value]) =>
            faqData[key as keyof Notice] !== value && key !== 'isEnabled',
        ),
      );
      // KT 외부망에서 요청할 경우 방화벽에서 body의 내용을 검사하는데
      // 'base64'가 포함되어 있으면 요청을 차단하기 때문에 'base6@4'로 치환하여 요청
      const body = JSON.parse(
        JSON.stringify(data).replace(/base64/g, 'base6@4'),
      );

      if (faqData.isEnabled !== formValue.isEnabled)
        await repositoryClient.patch('/admin-faq/enabled', {
          faqId,
          isEnabled: formValue.isEnabled,
        });
      return repositoryClient.patch(`/admin-faq/${faqId}`, body);
    },
  });

  const { mutate: deleteFAQ } = useMutation({
    onError: (e) => {
      openModal(
        <AlertDialog
          title="에러"
          message={repositoryUtils.getErrorMessage(e)}
        />,
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/admin-faq/${faqId}`, { faqId }],
      });
      navigate('/faq');
    },
    mutationFn: () => repositoryClient.delete(`/admin-faq/${faqId}`),
  });

  useEffect(() => {
    setFormValue({
      category: faqData.category,
      title: faqData.title,
      contents: faqData.contents,
      nextFaqId: faqData.nextFaqId,
      isEnabled: faqData.isEnabled,
    });
  }, [faqData]);

  return (
    <ContentBox
      css={css`
        gap: 24px;
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;
        `}
      >
        <StyledH1>FAQ 관리</StyledH1>
      </div>

      <StyledForm
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          openModal(
            <PromptDialog
              title="확인"
              message="FAQ를 저장하시겠습니까?"
              onConfirm={updateFAQ}
            />,
          );
        }}
      >
        <label>
          ID
          <input readOnly key={faqData.id} defaultValue={faqData.id} />
        </label>

        <label>
          카테고리
          <select
            required
            key={faqData.category}
            defaultValue={faqData.category}
            onChange={(newValue) =>
              setFormValue((prev) => ({
                ...prev,
                category: newValue.target.value,
              }))
            }
          >
            {Object.entries(faqCategories).map(
              ([category, displayCategory]) => (
                <option key={category} value={category}>
                  {displayCategory}
                </option>
              ),
            )}
          </select>
        </label>

        <label>
          제목
          <input
            required
            key={faqData.title}
            defaultValue={faqData.title}
            onChange={(newValue) =>
              setFormValue((prev) => ({
                ...prev,
                title: newValue.target.value,
              }))
            }
          />
        </label>

        <label htmlFor="content">
          답변
          <TextEditor
            key={faqData.contents}
            initialContent={faqData.contents.replace(/base6@4/g, 'base64')}
            onChange={(newValue) =>
              setFormValue((prev) => ({ ...prev, contents: newValue }))
            }
          />
        </label>

        <label htmlFor="content">
          활성화
          <input
            type="checkbox"
            checked={formValue.isEnabled}
            onChange={({ currentTarget: { checked } }) =>
              setFormValue((prev) => ({
                ...prev,
                isEnabled: checked,
              }))
            }
          />
        </label>

        <label>
          순서(다음으로 보여지는 FAQ의 ID)
          <input
            key={faqData.nextFaqId}
            value={
              // eslint-disable-next-line no-nested-ternary
              formValue.isEnabled
                ? formValue.nextFaqId && formValue.nextFaqId !== -1
                  ? formValue.nextFaqId
                  : '순서의 마지막입니다.'
                : '비활성화 되었습니다.'
            }
            onChange={(newValue) =>
              setFormValue((prev) => ({
                ...prev,
                nextFaqId: Number(newValue.target.value),
              }))
            }
            disabled={!formValue.isEnabled}
          />
        </label>

        <label>
          생성자
          <input
            readOnly
            key={faqData.creatorEmail}
            defaultValue={faqData.creatorEmail}
          />
        </label>

        <label>
          생성일자
          <input
            readOnly
            key={faqData.createdAt}
            defaultValue={utilTime.format(Date.parse(faqData.createdAt), false)}
          />
        </label>

        <label>
          수정자
          <input
            readOnly
            key={faqData.modifierEmail}
            defaultValue={faqData.modifierEmail}
          />
        </label>

        <label>
          수정일자
          <input
            readOnly
            key={faqData.modifiedAt}
            defaultValue={utilTime.format(
              Date.parse(faqData.modifiedAt),
              false,
            )}
          />
        </label>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 16px;
            `}
          >
            <Button form>저장</Button>
            <Button
              textColor={utils.style.color.grey500}
              baseColor={utils.style.color.grey200}
              onClick={() => {
                navigate('..');
              }}
            >
              취소
            </Button>
          </div>
          <Button
            baseColor={utils.style.color.highlight1}
            onClick={() => {
              openModal(
                <DeleteDialog
                  type="FAQ"
                  subjectText={faqData.title}
                  confirmText={faqData.title}
                  onConfirm={() => {
                    deleteFAQ();
                  }}
                />,
              );
            }}
          >
            삭제
          </Button>
        </div>
      </StyledForm>
    </ContentBox>
  );
};
