import { repositoryUtils } from '@admin/repository/utils';
import { utils } from '@admin/utils';
import axios from 'axios';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';

interface CreateRes {
  userEmail: string; // 유저 이메일
  userName: string; // 유저 이름
  registerDate: string; // 유저 등록일
  isOldRegister: boolean; // 기존 유저 여부
  singleGoodsOrderCount: number; // 단품 주문 개수
  singleGoodsOrderAmount: number; // 단푼 주문 금액
  singleGoodsVoiceCount: number; // 마이 AI 보이스 개수
  bundleVoiceCount: number; // 번들 보이스 개수
  currentEnableVoiceCount: number; // 현재 사용 가능한 보이스 개수
  voiceCount: number; // 날짜 이후 생성한 보이스 개수
  notDeleteVoiceCount: number; // 날짜 이후 생성한 삭제하지 않은 보이스 개수
  allVoiceCount: number; // 전체 보이스 개수
  activeVoiceCount: number; // 활성화된 보이스 개수
  deleteVoiceCount: number; // 삭제된 보이스 개수
  isCancel: boolean; // 취소 여부
  // 환불 대상 1년 미만 AI 보이스 개수 -> 보이스 생성일을 기준
  refundVoiceCount: number;
  // 강제환불 대상 -> AI 생성권으로 가지고 있는 경우, 보이스로 가지고 있으나 생성일이 1년 미만
  isRefund: boolean;
  // 고객 요청 시 활불 대상
  isCustomerRefund: boolean;
  // 단건 미사용 -> 미사용된 이용권 -> 번들 개수 제거
  notUseSingleGoodsVoiceCount: number;
  // 단건 1년 미만 -> 1년 미만 보이스 개수 - 번들로 얻은 이용권 개수
  notUseSingleGoodsVoiceCount1Year: number;
  // 번들 미사용 -> 미사용된 이용권 -> 단건 개수 제거
  notUseBundleVoiceCount: number;
  // 번들 1년 미만 -> 1년 미만 보이스 개수 - 단건으로 얻은 이용권 개수
  notUseBundleVoiceCount1Year: number;
}

interface SingleData {
  // 유저 이메일
  userEmail: string;
  // 유저 이름
  userName: string;
  // 거래 날짜
  transactionDate: string;
  // 주문 ID
  orderId: number;
  // TID
  transactionId: string;
  // 주문 금액
  amount: number;
  // 주문 수량
  count: number;
  // 2배 이벤트 여부
  isDoubleEvent: boolean;
  // 반값 이벤트 여부
  isHalfEvent: boolean;
}

interface MiddleSingleData {
  // 유저 이메일
  userEmail: string;
  // 유저 이름
  userName: string;
  // 거래 날짜
  transactionDate: string;
  // 주문 ID
  orderId: number;
  // 승인 번호
  approvalId: string;
  // TID
  transactionId: string;
  // 주문 금액
  amount: number;
  // 주문 수량
  count: number;
  // 단가
  unitPrice: number;
  // 이용권 여부
  isGoodsVoice: boolean;
  // 목소리 환불 건 여부
  isVoice: boolean;
  // 목소리 이름
  voiceName: string;
  // 목소리 생성일
  voiceCreateDate: string;
  // 보상일수
  rewardDays: number;
  // 환불 금액
  refundAmount: number;
  // 번들 여부
  isBundle: boolean;
  // 가맹점명
  merchantInfoCoNm: string;
  // 구분
  cardCl: string;
  // 결제사
  appNm: string;
  // 승인 일자
  appDt: string;
  // 카드 번호
  cardNo: string;
}

interface CancelSingleData {
  // 가맹점명
  merchantInfoCoNm: string;
  // 구분
  cardCl: string;
  // 결제사
  appNm: string;
  // 승인 일자
  appDt: string;
  // 카드 번호
  cardNo: string;
  // 승인 번호
  appNo: string;
  // 승인 금액
  amount: number;
  // 취소 금액
  refundAMount: number;
}

interface BundleRes {
  userEmail: string;
  userName: string;
  isAiaraEvent: boolean;
  transactionDate?: string;
  orderId?: number;
  transactionId?: number;
  amount?: number;
}

interface DataResponse<T> {
  success: string;
  message: string;
  data: T;
}

const humeloAxios = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: { 'Service-Code': '001WEB001' },
});

// ExcelDownload

const createDownload = async (
  data: CreateRes[],
  date: string,
  aivoiceDate: string,
) => {
  const wb = XLSX.utils.book_new();

  const headers = [
    [
      '',
      '계정(이메일)',
      '고객명',
      '회원가입일',
      `${dayjs(date).format('YY.M.D')} 회원가입 여부`,
      '마이 AI 보이스 단건구매 총 결제 횟수',
      '마이 AI 보이스 단건구매 총 결제금액',
      '단건구매로 취득한 마이 AI 보이스 총 이용권 개수',
      '번들 요금제로 취득한 마이 AI 보이스 이용권 개수',
      '사용하지 않고 남은 마이 AI 보이스 생성 개수',
      `${dayjs(aivoiceDate).format(
        'YY.M.D',
      )} 이후 생성한 마이 AI 보이스 개수 (삭제된 보이스 포함)`,
      `${dayjs(aivoiceDate).format(
        'YY.M.D',
      )} 이후 생성한 마이 AI 보이스 중 삭제되지 않은 마이 AI 보이스 개수`,
      '생성한 모든 마이 AI 보이스 개수 (전체기간 및 삭제된 보이스 포함)',
      '환불대상 1년미만 AI 보이스 개수',
      '(전체기간) 활성화 상태 AI 보이스 개수',
      '(전체기간) 삭제된 AI 보이스 개수',
      '환불대상 고객 여부',
      '강제 환불대상',
      '고객 요청 시 환불대상',
      '단건 미사용',
      '단건 1년미만',
      '번들 미사용',
      '번들 1년미만',
    ],
  ];

  const res = data.map((item, i) => [
    i + 1,
    item.userEmail,
    item.userName,
    dayjs(repositoryUtils.parseDate(item.registerDate)).format('YYYY-MM-DD'),
    item.isOldRegister ? 'O' : '',
    item.singleGoodsOrderCount,
    item.singleGoodsOrderAmount,
    item.singleGoodsVoiceCount,
    item.bundleVoiceCount,
    item.currentEnableVoiceCount,
    item.voiceCount,
    item.notDeleteVoiceCount,
    item.allVoiceCount,
    item.refundVoiceCount,
    item.activeVoiceCount,
    item.deleteVoiceCount,
    item.isCancel ? 'O' : '',
    item.isRefund ? 'O' : '',
    item.isCustomerRefund ? 'O' : 'X',
    item.notUseSingleGoodsVoiceCount,
    item.notUseSingleGoodsVoiceCount1Year,
    item.notUseBundleVoiceCount,
    item.notUseBundleVoiceCount1Year,
  ]);
  const ws = XLSX.utils.aoa_to_sheet([...headers, ...res]);
  XLSX.utils.book_append_sheet(wb, ws);

  XLSX.writeFile(
    wb,
    `잔여 이용권 및 마이 AI 보이스 생성 현황 ${dayjs().format(
      'YYYY-MM-DD',
    )}.xlsx`,
  );
};

const singleDownload = async (data: SingleData[]) => {
  const wb = XLSX.utils.book_new();

  const headers = [
    [
      '',
      '계정(이메일)',
      '고객명',
      '마이 AI 보이스 단건 결제일',
      '주문번호',
      'TID',
      '마이 AI 보이스 단건 결제 금액',
      '취득한 마이 AI 보이스 이용권 개수',
      '반값 이벤트 적용 여부',
      '1+1 이벤트 적용 여부',
    ],
  ];

  const res = data.map((item, i) => [
    i + 1,
    item.userEmail,
    item.userName,
    dayjs(repositoryUtils.parseDate(item.transactionDate)).format('YYYY-MM-DD'),
    item.orderId,
    item.transactionId,
    item.amount,
    item.count,
    item.isHalfEvent ? 'O' : '',
    item.isDoubleEvent ? 'O' : '',
  ]);
  const ws = XLSX.utils.aoa_to_sheet([...headers, ...res]);
  XLSX.utils.book_append_sheet(wb, ws);

  XLSX.writeFile(wb, `단건 결제 현황 ${dayjs().format('YYYY-MM-DD')}.xlsx`);
};

const bundleDownload = async (data: BundleRes[]) => {
  const wb = XLSX.utils.book_new();

  const headers = [
    [
      '',
      '계정(이메일)',
      '고객명',
      '번들 요금제로 마이 AI 보이스 이용권 취득한 결제일',
      '주문번호',
      'TID',
      '결제금액',
    ],
  ];

  const res = data.map((item, i) => [
    i + 1,
    item.userEmail,
    item.userName,
    item.transactionDate
      ? dayjs(repositoryUtils.parseDate(item.transactionDate)).format(
          'YYYY-MM-DD',
        )
      : '',
    item.orderId,
    item.transactionId,
    item.amount,
  ]);
  const ws = XLSX.utils.aoa_to_sheet([...headers, ...res]);
  XLSX.utils.book_append_sheet(wb, ws);

  XLSX.writeFile(
    wb,
    `번들 요금제로 취득한 마이 AI 보이스 결제 이력 ${dayjs().format(
      'YYYY-MM-DD',
    )}.xlsx`,
  );
};

const refundDownload = async (
  data: MiddleSingleData[],
  cancelData: CancelSingleData[],
  isBundle: boolean,
) => {
  const wb = XLSX.utils.book_new();

  const firstSheet = (() => {
    const filterData = data.filter((item) => item.isBundle === isBundle);

    const headers = [
      [
        '',
        '계정(이메일)',
        '고객명',
        isBundle ? '번들 요금제 결제일' : '마이 AI 보이스 단건 결제일',
        '주문번호',
        '승인번호',
        'TID',
        isBundle ? '번들 요금제 결제 금액' : '마이 AI 보이스 단건 결제 금액',
        '취득한 마이 AI 보이스 이용권 개수',
        '보이스개당단가',
        '미사용권 반환 여부',
        '1년미만 목소리 반환여부',
        '1년미만 목소리명(표시이름)',
        '1년미만 목소리 생성일',
        '보상일수',
        '환불금액',
      ],
    ];

    const res = filterData.map((item, i) => [
      i + 1,
      item.userEmail,
      item.userName,
      item.transactionDate
        ? dayjs(repositoryUtils.parseDate(item.transactionDate)).format(
            'YYYY-MM-DD',
          )
        : '',
      item.orderId,
      item.approvalId,
      item.transactionId,
      item.amount,
      item.count,
      item.unitPrice,
      item.isGoodsVoice ? 'O' : '',
      item.isVoice ? 'O' : '',
      item.voiceName,
      item.voiceCreateDate
        ? dayjs(repositoryUtils.parseDate(item.voiceCreateDate)).format(
            'YYYY-MM-DD',
          )
        : '',
      item.rewardDays,
      item.refundAmount,
    ]);

    return { headers, res };
  })();

  const secondSheet = (() => {
    const headers = [
      [
        'NO',
        '가맹점명',
        '구분',
        '결제사',
        '승인일자',
        '카드번호',
        '승인번호',
        '승인금액',
        '(부분) 취소금액',
      ],
    ];

    const res = cancelData.map((item, i) => [
      i + 1,
      item.merchantInfoCoNm,
      item.cardCl === '0' ? '신용' : '체크',
      item.appNm,
      item.appDt
        ? dayjs(repositoryUtils.parseDate(item.appDt)).format('YYYY-MM-DD')
        : '',
      item.cardNo,
      item.appNo,
      item.amount,
      item.refundAMount,
    ]);

    return { headers, res };
  })();

  const firstWs = XLSX.utils.aoa_to_sheet([
    ...firstSheet.headers,
    ...firstSheet.res,
  ]);
  const secondWs = XLSX.utils.aoa_to_sheet([
    ...secondSheet.headers,
    ...secondSheet.res,
  ]);

  XLSX.utils.book_append_sheet(wb, firstWs, '중간 점검용');
  XLSX.utils.book_append_sheet(wb, secondWs, '스마트로 제출용');

  XLSX.writeFile(
    wb,
    `${isBundle ? '번들 환불 내역' : '단건 환불 내역'} ${dayjs().format(
      'YYYY-MM-DD',
    )}.xlsx`,
  );
};

// Data Fetching

async function getCreateData(token: string, date: string, voiceDate: string) {
  try {
    const {
      data: { data },
    } = await humeloAxios.get<DataResponse<CreateRes[]>>(
      '/order/member-stats',
      {
        params: {
          date,
          voiceDate,
        },
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

async function getSingleData(token: string, date: string, voiceDate: string) {
  try {
    const {
      data: { data },
    } = await humeloAxios.get<DataResponse<SingleData[]>>(
      '/order/single-goods',
      {
        params: {
          date,
          voiceDate,
        },
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

async function getMiddleSingleData(
  token: string,
  date: string,
  voiceDate: string,
) {
  try {
    const {
      data: { data },
    } = await humeloAxios.get<DataResponse<MiddleSingleData[]>>(
      '/order/single-goods/middle',
      {
        params: {
          date,
          voiceDate,
        },
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

async function getCancelSingleData(
  token: string,
  date: string,
  voiceDate: string,
) {
  try {
    const {
      data: { data },
    } = await humeloAxios.get<DataResponse<CancelSingleData[]>>(
      '/order/single-goods/cancel',
      {
        params: {
          date,
          voiceDate,
        },
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

async function getBundleData(token: string) {
  try {
    const {
      data: { data },
    } = await humeloAxios.get<DataResponse<BundleRes[]>>(
      '/order/plan/voice-bundle',
      {
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  } catch (e) {
    console.log(`Error: ${e}`);
  }
}

// 다운로드 함수
export async function createDownloadExcel(date: string, voiceDate: string) {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const data = await getCreateData(token, date, voiceDate);

    if (data) createDownload(data, date, voiceDate);
  }
}

export async function singleDownloadExcel(date: string, voiceDate: string) {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const data = await getSingleData(token, date, voiceDate);

    if (data) singleDownload(data);
  }
}

export async function bundleDownloadExcel() {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const data = await getBundleData(token);

    if (data) bundleDownload(data);
  }
}

export async function singleRefundDownloadExcel(
  date: string,
  voiceDate: string,
) {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const data = await getMiddleSingleData(token, date, voiceDate);
    const cancelData = await getCancelSingleData(token, date, voiceDate);

    if (data && cancelData) refundDownload(data, cancelData, false);
  }
}

export async function bundleRefundDownloadExcel(
  date: string,
  voiceDate: string,
) {
  const { accessToken: token } = utils.token.get();

  if (token) {
    const data = await getMiddleSingleData(token, date, voiceDate);
    const cancelData = await getCancelSingleData(token, date, voiceDate);

    if (data && cancelData) refundDownload(data, cancelData, true);
  }
}
